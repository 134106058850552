@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #000000;
  
}

.logo-wrap{
  width: 200px;
  height: 65px;
  overflow: hidden;
  margin-left: -20px;
}
.mainhero{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clickableLink{
  cursor: pointer;
}